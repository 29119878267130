import * as React from 'react';
import { Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Especialidades from "./pages/Especialidades";
import Acordos from "./pages/Acordos";
import Equipa from "./pages/Equipa";
import Planos from "./pages/Planos";
import Contactos from "./pages/Contactos";

function CVLRoutes() {
    return (

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Especialidades" element={<Especialidades />} />
            <Route path="/Equipa" element={<Equipa />} />
            <Route path="/Acordos" element={<Acordos />} />
            <Route path="/Planos" element={<Planos />} />
            <Route path="/Contactos" element={<Contactos />} />
        </Routes>
    );
}
export default CVLRoutes;
