import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Planos() {
    const carouselImage = require('../img/CarouselBg.jpg');
    const bkgImgHome = {
        backgroundImage: `url(${carouselImage})`,
        backgroundSize: 'cover',
    };
    const googleDocs = {
        height: 500,
        width: '100%'
    };

    const test = {
        width: '100%'
    };

    return (
        <div style={test}>
            <div style={bkgImgHome} className="page-header">
                <Typography variant="h6" gutterBottom component="div">
                    Planos de Consultas
                </Typography>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                }}
            >
            <iframe src="https://clinicavaledelobos.pt/PlanoConsultasPediatria.pdf" style={googleDocs} frameBorder="0" ></iframe>
            </Box>
        </div>
    );
}