import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Grid from '@mui/material/Grid';
import { useState } from "react";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    var steps = 6;
    const items = []

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    for (var i = 1; i <= steps; i++) {
        items.push(
            <Box
                component="img"
                sx={{
                    maxHeight: 400,
                    height: 400,
                    display: 'block',
                    maxWidth: 900,
                    overflow: 'hidden',
                    width: '100%',
                    object_fit: 'cover'
                }}
                src={`Carousel${i}.jpg`}
            />
        );
    }

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={1} md={3} />
            <Grid item xs={10} md={6}>
                <Box sx={{ maxHeight: 400, flexGrow: 1 }} className="carousel">
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        interval={6000}
                    >
                        {items.map((item, index) => (
                            <div key={index}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    item
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        style={{
                            backgroundColor: theme.palette.grey[400],
                            maxWidth: 900
                        }}
                        steps={steps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                color="secondary"
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === steps - 1}
                            >
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button color="secondary" size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                            </Button>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={1} md={3} />
        </Grid>
    );
}

export default SwipeableTextMobileStepper;
