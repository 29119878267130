import * as React from 'react';
import Box from '@mui/material/Box';
import '../components/Components.css';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';

export default function Contactos() {

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        p: 1,
                        m: 1,
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1
                        }}>
                        <IconButton href="https://goo.gl/maps/73DqbjvNdvQQa4Tv8" target="_blank" rel="noopener noreferrer" >
                            <img src="CVL_mapa.png" alt="Clínica Vale de Lobos - Localização" width={350} />
                        </IconButton >
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: 3,
                        m: 1,
                        maxWidth: 300
                    }}>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                        }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <LocationOnIcon fontSize="small" />
                            <Typography variant="body2" gutterBottom>
                                Rua Álvaro Pires de Miranda 27 R/C, Telheiro, Barreira, 2410-538 Leiria
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <EmailIcon fontSize="small" />
                            <Typography variant="body2" gutterBottom>
                                geral@clinicavaledelobos.pt
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <LocalPhoneIcon fontSize="small" />
                            <Typography variant="body2" gutterBottom>
                                <a href="tel:244735205" className="nostyle">244 735 205 **</a>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <PhoneAndroidIcon fontSize="small" />
                            <Typography variant="body2" gutterBottom>
                                <a href="tel:967308024" className="nostyle">967 308 024 *</a>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}