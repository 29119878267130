import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Acordos() {
    const carouselImage = require('../img/CarouselBg.jpg');
    const bkgImgHome = {
        backgroundImage: `url(${carouselImage})`,
        backgroundSize: 'cover',
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <div style={bkgImgHome} className="page-header" >
                    <Typography variant="h6" gutterBottom component="div">
                        Parcerias
                    </Typography>
                </div>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="ACP - Automóvel Club de Portugal">
                        <img src="ACP.png" alt="ACP - Automóvel Club de Portugal" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="ADSE - Instituto de Proteção e Assistência na Doença">
                        <img src="ADSE.png" alt="ADSE - Instituto de Proteção e Assistência na Doença" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação De Pais - Colégio Conciliar de Maria Imaculada">
                        <img src="AssociacaoDePais-CCMI.png" alt="Associação De Pais - Colégio Conciliar de Maria Imaculada" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação De Pais das Cortes">
                        <img src="AssociacaoPaisCortes.png" alt="Associação De Pais das Cortes" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação De Pais - EB/JI Cruz D'Areia">
                        <img src="AssociacaoDePais-CruzDAreia.png" alt="Associação De Pais - EB/JI Cruz D'Areia" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação de Pais - Escola Básica de Barreira">
                        <img src="AssociacaoPaisCEB.png" alt="Associação de Pais - Escola Básica de Barreira" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação de Pais - Escola Quinta da Alçada">
                        <img src="AssociacaoPaisQuintaAlcada.png" alt="Associação de Pais - Escola Quinta da Alçada" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação De Pais das Escolas da Batalha">
                        <img src="AssociacaoPaisBatalha.png" alt="Associação De Pais das Escolas da Batalha" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Associação de Pais - EB1  e JI DO Vidigal">
                        <img src="AssociacaoPaisVidigal.png" alt="Associação de Pais - EB1  e JI DO Vidigal" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Cheque Dentista - Clílica Aderente">
                        <img src="ChequeDentista.jpg" alt="Cheque Dentista - Clílica Aderente" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Cubo Mágico - Infantário e Jardim de Infância">
                        <img src="CuboMagico.png" alt="Cubo Mágico - Infantário e Jardim de Infância" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Dominó - Infantário e Jardim de Infância">
                        <img src="Domino.jpg" alt="Dominó - Infantário e Jardim de Infância" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Essência do Saber - Centro de explicações">
                        <img src="EssenciaDoSaber.png" alt="Essência do Saber - Centro de explicações" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Ginásios da Educação Da Vinci - Centro de explicações">
                        <img src="GinasiosDaEducacaoDaVinci.png" alt="Ginásios da Educação Da Vinci - Centro de explicações" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Instituto Politécnico de Leiria">
                        <img src="InstitutoPolitecnicoDeLeiria.jpg" alt="Instituto Politécnico de Leiria" width={275} />
                    </Tooltip>
                </Box>
                
                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="QuebraCabeças - Centro de apoio escolar e explicações">
                        <img src="QuebraCabecas.png" alt="QuebraCabeças - Centro de apoio escolar e explicações" width={275} />
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <Tooltip title="Tentativa - Infantário e Jardim de Infância">
                        <img src="Tentativa.png" alt="Tentativa - Infantário e Jardim de Infância" width={275} />
                    </Tooltip>
                </Box>
            </Box>
        </div>
    );
}