import * as React from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import NavBar from "./components/NavBar";
import CVLRoutes from "./CVLRoutes";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
//import { generateSitemap } from 'react-sitemap-generator';

import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            <span>Copyright &copy;
                {new Date().getFullYear()}
                {'.'}
            </span>
        </Typography>
    );
}

function App() {

    //Debug this function in Chrome to get the sitemap.xml
    //generateSitemap({
    //    url: 'https://clinicavaledelobos.pt',
    //    routes: [CVLRoutes],
    //    output: '../public',
    //});

    return (
        <Router>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}>
                <CssBaseline />
                <NavBar children={<React.Fragment />} />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 15 }}
                >
                    <CVLRoutes />
                </Box>

                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[800],
                    }}>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <div>
                                <Typography variant="body1">
                                    Cl&#237;nica Vale de Lobos
                                </Typography>
                                <Copyright />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="center">
                                <IconButton href="https://www.instagram.com/clinicavlobos/" target="_blank" rel="noopener noreferrer">
                                    <Instagram />
                                </IconButton>
                                <IconButton href="https://www.facebook.com/Cl%C3%ADnica-Vale-de-Lobos-109395185102224/" target="_blank" rel="noopener noreferrer">
                                    <Facebook />
                                </IconButton>
                                {/*<IconButton href="https://twitter.com/clinicavlobos" target="_blank" rel="noopener noreferrer">*/}
                                {/*    <Twitter />*/}
                                {/*</IconButton>*/}
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Box display="flex" justifyContent="flex-end">
                                <Typography variant="body2" color="text.secondary">
                                    <a href="tel:967308024" className="nostyle">967 308 024 *</a> | <a href="tel:244735205" className="nostyle">244 735 205 **</a>
                                    <br />
                                    geral@clinicavaledelobos.pt
                                    <div style={{ fontSize: 8 }}>&nbsp; * Chamada para a rede m&oacute;vel nacional</div>
                                    <div style={{ fontSize: 8 }}>** Chamada para a rede fixa nacional</div>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Router>
    );
}

export default App;
