import * as React from 'react';
import Carousel from "../components/Carousel";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Home() {

    const carouselImage = require('../img/CarouselBg.jpg');
    const bkgImgHome = {
        backgroundImage: `url(${carouselImage})`,
        backgroundSize: 'cover',
        height: 500,
        width: '100%'
    };

    return (
        <div>
            <div style={bkgImgHome} >
                <Carousel />
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    p: 1,
                    m: 1,
                }}>
                <Box
                    sx={{
                        p: 1,
                        m: 1,
                    }}>
                    <img src="vinil_gab_medico.png" alt="Clínica Vale de Lobos" width={300} />
                </Box>
                <Box
                    sx={{
                        p: 3,
                        m: 1,
                        maxWidth: 300
                    }}>
                    <Typography variant="h5" gutterBottom component="div">
                        A nossa missão
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                        A Saúde da nossa Família é o bem mais precioso. <br />
                        Propomo-nos a prestar um serviço privado de saúde com excelência para todos!
                    </Typography>
                    <br />
                    <Typography variant="h5" gutterBottom component="div">
                        A nossa visão
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                        Acompanhar o indivíduo desde a preparação para o parto, até à sua idade de reforma.
                    </Typography>
                    <Typography variant="h5" gutterBottom component="div">
                        Os nossos valores
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                        Os profissionais que colaboram com a Clínica, são selecionados pela sua experiência/competência, conferindo tranquilidade a todos os que em nós confiam para cuidar do bem estar de toda a sua Família.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body2" gutterBottom>
                        A gerência,<br />
                        Clínica Vale de Lobos
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}


//import Modal from '@mui/material/Modal';
//import CardMedia from '@mui/material/CardMedia';


//const [open, setOpen] = React.useState(false);
//const handleOpen = () => setOpen(true);
//const handleClose = () => setOpen(false);

//React.useEffect(() => {
//    handleOpen();
//}, [])

//const style = {
//    position: 'absolute' as 'absolute',
//    top: '50%',
//    left: '50%',
//    transform: 'translate(-50%, -50%)',

//};

//<Modal
//    open={open}
//    onClose={handleClose}
//    aria-labelledby="modal-modal-title"
//    aria-describedby="modal-modal-description"
//>
//    <Box sx={style}>
//        <CardMedia
//            component="video"
//            image="CVLIntro.mp4"
//            autoPlay
//        />
//    </Box>
//</Modal>