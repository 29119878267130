import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';



interface Props {
    window?: () => Window;
    children: React.ReactElement;
}

const pages = [
    { path: 'Home', label: 'Início' },
    { path: 'Especialidades', label: 'Serviços' },
    { path: 'Equipa', label: 'Equipa' },
    { path: 'Acordos', label: 'Parcerias' },
    { path: 'Planos', label: 'Planos' },
    { path: 'Contactos', label: 'Contactos' }
];

function ElevationScroll(props: Props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}


const NavBar = (props: Props) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar style={{ background: '#FFFFFF' }}>
                    <Container maxWidth={false}>
                        <Toolbar>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="Menu de navegacao"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="primary"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <Link to={'/' + page.path} style={{ textDecoration: 'none', color: 'black' }}>
                                            <MenuItem onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center" >{page.label}</Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                </Menu>
                            </Box>

                            <Grid container>
                                <Grid item xs>
                                    <img src="LogoHeader.png" alt="Clínica Vale de Lobos" width={275} style={{ marginTop: 5 }} />
                                </Grid>
                                <Grid
                                    item xs="auto"
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-end">
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mt: 5 }}>
                                        {pages.map((page) => (
                                            <Button
                                                href={'/' + page.path}
                                                key={page.path}
                                                onClick={handleCloseNavMenu}
                                                sx={{ my: 2, color: 'black', display: 'block' }}
                                            >
                                                {page.label}
                                            </Button>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
        </div>
    );
};
export default NavBar;