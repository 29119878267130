import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


export default function Especialidades() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const especialidades = [
        {
            image: 'Amamentacao.jpg',
            alt: 'Acompanhamento da Amamentação',
            title: 'Acompanhamento da Amamentação',
            description: 'Consultas de Amamentação. Extração, recolha e conservação de leite materno. Sessão esclarecimento sobre a Introdução da alimentação complementar (tradicional e BLW).',
        },
        {
            image: 'Acupunctura.jpg',
            alt: 'Acupunctura',
            title: 'Acupunctura',
            description: 'A Acupuntura é uma técnica milenar da medicina tradicional chinesa tratando tanto a dor aguda como a dor crónica. A medicina ocidental explica os efeitos da acupuntura através da estimulação e libertação de substâncias endógenas, endorfinas, neurotransmissores e hormonas que modelam o sistema nervoso central e autónomo, promovendo o equilíbrio do organismo.',
        },
        {
            image: 'ConsultaDoViajante.png',
            alt: 'Consulta do Viajante',
            title: 'Consulta do Viajante',
            description: 'Para que a sua viagem seja uma experiência inesquecível (pelos melhores motivos), a Consulta do Viajante deve ser a primeira paragem no seu itinerário! Nesta consulta abordaremos os riscos que poderá encontrar no seu destino, como as doenças transmitidas por mosquitos/ moscas, a segurança alimentar, os cuidados a ter com animais, os perigos da altitude, entre muitos outros.Existem vacinas e medicamentos que são importantes para a(o) proteger! O aconselhamento dependerá do destino, da duração da viagem, do tipo de atividades previstas e do seu estado de saúde, pelo que é totalmente personalizado para si',
        },
        {
            image: 'Enfermagem.jpg',
            alt: 'Enfermagem de Família',
            title: 'Enfermagem de Família',
            description: 'Serviço de enfermagem personalizado e centrado nas necessidades da família.',
        },
        {
            image: 'HarmOrofacial.jpg',
            alt: 'Harmonização Orofacial',
            title: 'Harmonização Orofacial',
            description: 'Com a Harmonização Orofacial procuramos oferecer o equilibrio e a harmonia do seu sorriso e face sendo o botox, o ácido hialurónico, skinboosters e Liftings com fios de PDO , em sinergia com a estética dentária, as nossas terapêuticas mais utilizadas. Tratamento anti- rugas da face superior, Pés de galinha, Lifting das sobrancelhas, Sorriso gengival, lifting do pescoço, Hiperidrose / Transpiração excessiva, Sulcos nasogenianos / “bigode - chinês”, Contorno e volumização dos lábios, Contorno / volume das maçãs do rosto, Linhas de marionete / preenchimento do canto da boca, Queixo, bruxismo, Rejuvenescimento das mãos.',
        },
        {
            image: 'Dentaria.jpg',
            alt: 'Medicina Dentária',
            title: 'Medicina Dentária',
            description: 'Dispomos das áreas de medicina dentária generalista, odontopediatria, ortodontia, implantologia, branqueamento dentário, endodontia, prótese dentária, dentisteria estética, coroas e facetas, oclusão e ATM, periodontologia e saúde oral na gravidez.',
        },
        {
            image: 'MedicinaGeral.png',
            alt: 'Medicina Geral e Familiar',
            title: 'Medicina Geral e Familiar',
            description: 'A Medicina Geral e Familiar é uma especialidade médica que promove os cuidados de saúde de todos os indivíduos, independentemente da idade, género, etnia ou estado de saúde. Foca-se no cuidado da pessoa, em termos de prevenção, diagnóstico, tratamento, reabilitação e paliativo. A Medicina Geral e Familiar trabalha em conjunto com outros profissionais, médicos e não médicos, promovendo a coordenação dos cuidados de saúde prestados, através da articulação entre os seus diferentes níveis. A Medicina Geral e Familiar é, pois, uma medicina personalizada que proporciona a cada um os cuidados que mais necessita, contribuindo para que todos possam desenvolver ao máximo as suas capacidades.',
        },
        {
            image: 'MedTradChinesa.jpg',
            alt: 'Medicina Tradicional Chinesa',
            title: 'Medicina Tradicional Chinesa',
            description: 'Consultas e tratamentos de Medicina Tradicional Chinesa como acupuntura, acupuntura estética, tuina, fitoterapia chinesa, dietética chinesa, ventosaterapia, moxabustão.',

        },
        {
            image: 'Nutricao.jpg',
            alt: 'Nutrição',
            title: 'Nutrição',
            description: 'A alimentação está intimamente ligada à qualidade de vida, apresentando um grande impacto na nossa saúde. O nosso padrão alimentar terá, certamente, influência na manutenção de saúde e/ ou na manifestação de doença e, assim sendo, a nutrição assume um papel preponderante na promoção de saúde e prevenção de doenças.',
        },
        {
            image: 'Osteopatia.png',
            alt: 'Osteopatia',
            title: 'Osteopatia',
            description: 'A osteopatia é um sistema autónomo de cuidados de saúde primário, que aborda o indivíduo de uma forma global, centrando-se nas causas e não apenas nos sintomas do paciente. Na sua prática clínica são utilizadas diversas técnicas (técnicas craniana, técnicas musculo - energéticas, técnicas de mobilização articular, técnicas de manipulação articular e ainda mobilização neural, etc.) quem visam essencialmente o sistema neuro - músculo - esquelético.Abordagem que têm revolucionado a área da Saúde, nomeadamente no tratamento da dor (aguda/crónica), nas mais diversas condições clínicas.',
        },                
        {
            image: 'Psicologia.jpg',
            alt: 'Psicologia',
            title: 'Psicologia',
            description: 'A consulta de Psicologia pretende dotar o paciente de um bem-estar tanto psíquico como emocional, ajudando a que possa atingir um nível de conhecimento confortável e positivo de si próprio, de forma a lidar de um modo mais satisfatório com os diversos desafios da sua vida.',
        },
        {
            image: 'TerapiaFala.jpg',
            alt: 'Terapia da Fala e Miofuncional',
            title: 'Terapia da Fala e Miofuncional',
            description: 'O terapeuta da fala é o responsável pela prevenção, avaliação, diagnóstico, tratamento e estudo científico da comunicação humana e das perturbações associadas, em todas as idades. Estas perturbações podem ser ao nível da comunicação, fala, linguagem, mas também relacionadas com funções auditivas, visuais, cognitivas, oro-musculares, respiratórias, deglutição e voz.',

        },
        {
            image: 'TerapiaFamiliarCasal.png',
            alt: 'Terapia Familiar e de Casal',
            title: 'Terapia Familiar e de Casal',
            description: 'A Terapia Familiar e de Casal é uma especialidade da Psicologia que se concentra nas emoções, pensamentos e comportamentos de indivíduos, casais e famílias, nos relacionamentos e no ambiente mais amplo em que atuam. Utiliza os amplos fundamentos da Psicologia focados, exclusivamente, na compreensão e na intervenção de sistemas relacionais (casais, famílias, grupos, organizações e sociedade em geral).',
        },
        
        //{
        //    image: 'Ginecologia.jpg',
        //    alt: 'Ginecologia e Obstetrícia',
        //    title: 'Ginecologia e Obstetrícia',
        //    description: 'A ginecologia e obstetrícia dedica-se à prevenção, diagnóstico e tratamento das doenças do sistema reprodutor feminino (ginecologia), bem como ao acompanhamento durante a gestação, no parto e pós-parto (obstetrícia).',
        //},
    ];

    const especialidadesjunior = [
        {
            image: 'ConsultaSonoJunior.jpg',
            alt: 'Consulta do Sono',
            title: 'Consulta do Sono',
            description: 'A consulta do sono foi criada para o paciente que sente que dorme mal ou porque uma segunda pessoa identifica sinais de um mau dormir, como o ressonar. A consulta do sono infantile é destinada a crianças e jovens, desde o bebé aos 18 anos de idade, que apresentem perturbações de sono.',
        },
        {
            image: 'EnfermagemJunior.jpg',
            alt: 'Enfermagem de Família',
            title: 'Enfermagem de Família',
            description: 'Segurança/Vacinas. Vigilância de peso/estatura/PC do bebé. Cuidados essenciais ao bebé (banho, lavagem nariz, limpeza olhos...). Esclarecimento de dúvidas de pediatria.'
        },
        {
            image: 'FisioterapiaPediatrica.png',
            alt: 'Fisioterapia Pediátrica',
            title: 'Fisioterapia Pediátrica',
            description: 'A Fisioterapia tem um papel fundamental no futuro de muitas crianças, ajudando a construir uma base sólida para que se possam desenvolver física, psicológica e emocionalmente. As famílias tem um papel crucial no processo de reabilitação, sendo parte do nosso trabalho informá-las e empoderá-las nesse sentido. Por isso, procuramos ver o bébé/criança no seu todo, incluindo a família e o ambiente em que está inserido.'
        },
        {
            image: 'MedicinaGeralJunior.png',
            alt: 'Medicina Geral e Familiar',
            title: 'Medicina Geral e Familiar',
            description: 'A Medicina Geral e Familiar é uma especialidade médica que promove os cuidados de saúde de todos os indivíduos, independentemente da idade, género, etnia ou estado de saúde. Foca-se no cuidado da pessoa, em termos de prevenção, diagnóstico, tratamento, reabilitação e paliativo. A Medicina Geral e Familiar trabalha em conjunto com outros profissionais, médicos e não médicos, promovendo a coordenação dos cuidados de saúde prestados, através da articulação entre os seus diferentes níveis. A Medicina Geral e Familiar é, pois, uma medicina personalizada que proporciona a cada um os cuidados que mais necessita, contribuindo para que todos possam desenvolver ao máximo as suas capacidades.',
        },
        {
            image: 'NutricaoInfantil.jpg',
            alt: 'Nutrição Infantil',
            title: 'Nutrição Infantil',
            description: 'A consulta de nutrição infantile tem como base a implementação de hábitos de nutricição corretos que tenham efeitos benéficos para a saúde e bem-estar da criança e do adolescente. Comer bem desde pequeno na escola e em casa é o lema.',
        },
        {
            image: 'Odontopediatria.jpg',
            alt: 'Odontopediatria',
            title: 'Odontopediatria',
            description: 'A Odontopediatria centra-se na saúde oral dos bebés, crianças e adolescentes, tendo como principal objetivo a prevenção da doença e, se necessário, o seu tratamento. No combate à cárie dentária, a prevenção é mais eficaz que o tratamento. Apostar na prevenção significa evitar tratamentos invasivos, mais traumatizantes para a criança e para os pais.',
        },
        {
            image: 'Pediatria.jpg',
            alt: 'Pediatria e Pediatria do Desenvolvimento',
            title: 'Pediatria e Pediatria do Desenvolvimento',
            description: 'A Pediatria é a especialidade médica que acompanha o crescimento e o desenvolvimento da criança e do adolescente até aos 18 anos, vigia o seu estado de saúde e diagnostica e trata as suas doenças. É uma especialidade multidisciplinar permitindo um acompanhamento global do estado de saúde das crianças e adolescentes.',
        },
        {
            image: 'PsicologiaEducacional.jpg',
            alt: 'Psicologia Clínica e Educacional',
            title: 'Psicologia Clínica e Educacional',
            description: 'A intervenção do Psicólogo na criança é fundamental sempre que se verifiquem problemas que impeçam o desenvolvimento saudável da criança. O Psicólogo investiga e analisa o comportamento, as emoções, a personalidade e a componente cognitiva da criança. O processo psicoterapêutico vai contribuir para o colmatar das dificuldades identificadas, potenciando os recursos internos da criança e favorecendo a sua maturação saudável. A intervenção é realizada através de atividades lúdicas, seja através de brincadeiras, desenhos e/ou jogos. Deste modo, a criança consegue expressar-se melhor. A participação dos pais no processo de psicoterapia da criança é fundamental para a sua evolução, uma vez que é necessário solicitar informações junto dos pais, e também, dar-lhes auxílio para o desenvolvimento satisfatório do processo psicoterapêutico.',
        },
        {
            image: 'TerapiaFalaJunior.jpg',
            alt: 'Terapia da Fala e Miofuncional',
            title: 'Terapia da Fala e Miofuncional',
            description: 'O terapeuta da fala é o responsável pela prevenção, avaliação, diagnóstico, tratamento e estudo científico da comunicação humana e das perturbações associadas, em todas as idades. Estas perturbações podem ser ao nível da comunicação, fala, linguagem, mas também relacionadas com funções auditivas, visuais, cognitivas, oro-musculares, respiratórias, deglutição e voz.',
        },
        {
            image: 'TerapiaOcupacional.jpg',
            alt: 'Terapia Ocupacional',
            title: 'Terapia Ocupacional',
            description: 'A terapia ocupacional tem como objetivo potenciar o melhor desempenho da criança em todas as ocupações do seu dia-a-dia, o que inclui a capacidade para brincar, comer, realizar a hygiene, vestir/despir, desempenhar as atividades escolares, interagir com os pares e adultos em vários contextos.',
        },
        //{
        //    image: 'Neuropediatria.jpg',
        //    alt: 'Neuropediatria',
        //    title: 'Neuropediatria',
        //    description: 'A Neuropediatria é o ramo da neurologia que se dedica ao estudo das doenças do desenvolvimento e maturação do sistema nervoso nas crianças e nos jovens. Atuamos ao nível da Perturbação do espetro do autismo, Hiperatividade e défice de atenção, Epilepsia ou convulsões, Cefaleias ou  dores de cabeça, Esclerose múltipla, Paralisia cerebral, Alterações neuromusculares (distrofia muscular e miopatias congênitas), Malformações cerebrais, Perturbações do sono, Acompanhamento de bebês de risco (prematuros), Alterações da linguagem e fluência do discurso.',
        //},

    ];

    const carouselImage = require('../img/CarouselBg.jpg');
    const bkgImgHome = {
        backgroundImage: `url(${carouselImage})`,
        backgroundSize: 'cover',
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'

                }}
            >
                <TabContext value={value}>
                    <div style={bkgImgHome} className="page-header">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Especialidades">
                                <Tab label="Especialidades" value="1" />
                                <Tab label="Especialidades Júnior" value="2" />
                            </TabList>
                        </Box>
                    </div>
                    <TabPanel value="1">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                p: 1,
                                m: 1,
                            }}>
                            {especialidades.map((especialidade) => (
                                <Box
                                    sx={{
                                        p: 1,
                                        m: 1,
                                    }}>
                                    <Card sx={{ maxWidth: 320 }}>
                                        <CardActionArea>

                                            <CardMedia
                                                component='img'
                                                height='225'
                                                image={especialidade.image}
                                                alt={especialidade.alt}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant='h5' component='div'>
                                                    {especialidade.title}
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary'>
                                                    {especialidade.description}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                p: 1,
                                m: 1,
                            }}>
                            {especialidadesjunior.map((especialidade) => (
                                <Box
                                    sx={{
                                        p: 1,
                                        m: 1,
                                    }}>
                                    <Card sx={{ maxWidth: 320 }}>
                                        <CardActionArea>

                                            <CardMedia
                                                component='img'
                                                height='225'
                                                image={especialidade.image}
                                                alt={especialidade.alt}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant='h5' component='div'>
                                                    {especialidade.title}
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary'>
                                                    {especialidade.description}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}